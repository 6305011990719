import { DarkThemeToggle, Button, Badge, Card, Banner } from "flowbite-react";
import { Link, Routes, Route } from 'react-router-dom';
import { CustomNavbar } from "./components/NavBar/NavBar";
import { CustomFooter } from "./components/Footer/Footer";
import { HiOutlineArrowRight, HiChevronRight } from "react-icons/hi";
import { Alerte1 } from "./components/Banner/Banner1";
import illustration1 from "./img/image-server.svg"
import illustration1Blue from "./img/image-server-blue.svg"
import serverDedie from './img/serveur_dedie.png'
import CarousselGame from './components/Caroussel/CarousselGame'
import { FAQ } from "./components/FAQ/faq";
import GamePage from './GamePage';
import CardHome from "./components/Card/Card1";
import MapChart from "./components/maps/map1";


// img 
import ScrollBlanc from "./img/scroll-blanc.png";
import ScrollNoir from "./img/scroll-noir.png";

//JS
import "./main.js";

function HomePage() {
  return (
    
    <main className=" bg-primary min-h-screen items-center justify-center gap-2">
      <section id="home" className="bg-white dark:bg-gray-800 ">
        <CustomNavbar />
        <div className="mx-auto grid max-w-screen-xl px-4 py-8 lg:grid-cols-12 lg:gap-8 lg:py-16 xl:gap-0">
          <div className="mr-auto place-self-center lg:col-span-7 space-y-3">
            <div id="badge-nouveaute" className="flex flex-wrap gap-2 ">
            <Link to="/game" className="inline-flex items-center space-x-4">   
                <Badge color="info">Nouveauté</Badge>{" "}
                <p className="font-light text-gray-500 dark:text-gray-400">
                  Serveur dédié
                </p>{" "}
                <HiChevronRight className="font-light text-gray-500 dark:text-gray-400" />
                <br />
                
                </Link>
            </div>
            <h1 className="mb-4 max-w-2xl text-4xl font-extrabold leading-none dark:text-white md:text-2xl xl:text-3xl">
              propulsez votre projet grace a JustMyHost
            </h1>
            <p className="mb-6 max-w-2xl font-light text-gray-500 dark:text-gray-400 md:text-lg lg:mb-8 lg:text-xl">
              Lorem ip sum jmen blc car aujourd'hui je me sens ananas et demain
              je me sentirais radiateur ascendant chévre
            </p>
            <div className="flex flex-wrap gap-2">
            <Button href="https://google.fr">
              Découvrez nos offres
              <HiOutlineArrowRight className="ml-2 h-5 w-5" />
            </Button>
            </div>
          </div>
          <div className="hidden lg:col-span-5 lg:mt-0 lg:flex">
            <img
              src={illustration1}
              sizes="500px"
              className="dark:block hidden"
            />            <img
            src={illustration1Blue}
            sizes="500px"
            className="block dark:hidden"
          />
          </div>
        </div>
        <div>
      <div className="flex justify-center absolute inset-x-0 bottom-10 h-16 position-static">
          <a href="#PointFort" className="scroll-down mouse effect1 border-black dark:border-white ">
            <span className="after:bg-black dark:after:bg-white"></span>
          </a>
        </div>
      </div>
      </section>
      <section id="PointFort" className="card bg-gray-100 dark:bg-gray-900 ">
      <div className="sm:text-center  py-8 px-4 ">
        <h1 className="mb-4  text-4xl font-extrabold leading-none dark:text-white md:text-5xl xl:text-4xl padding-1">Nos Points Forts</h1>
        </div>
        <p className="mt-4 max-w-2xl text-xl text-gray-600 dark:text-gray-400 lg:mx-auto sm:text-center">
        Voici quelques avantages dont vous bénéficierez en étant client chez nous.
            </p>
        <div className="mx-auto grid max-w-screen-xl px-4 py-8 lg:grid-cols-12 lg:gap-8 lg:py-16 xl:gap-0">
          <div className="mr-auto space-y-5 place-self-center lg:col-span-7">
            <Card className="max-w-sm" horizontal>
              <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                 Pouvoir d'achat
              </h5>
              <p className="font-normal text-gray-700 dark:text-gray-400">
              Profitez de nos offres à partir de 0,99€ pour les petits budgets, avec la garantie de n'avoir aucun frais supplémentaire.
              </p>
            </Card>
            <Card className="max-w-sm" horizontal>
              <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                Serveurs haut de gamme
              </h5>
              <p className="font-normal text-gray-700 dark:text-gray-400">
              Nos serveurs sont équiper de composant haut de gamme et évolutif, pour ne pas manquer en puissance !
              </p>
            </Card>
            <Card className="max-w-sm" horizontal>
              <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                Services client Réactif
              </h5>
              <p className="font-normal text-gray-700 dark:text-gray-400">
              Notre support sera vous aider au moindre problème que vous rencontrez en quelques minutes.
              </p>
            </Card>
          </div>
          <div className="hidden lg:col-span-5 lg:mt-0 lg:flex">
            <img src={serverDedie} sizes="500px" />
          </div>
        </div>
      </section>
      <section className="bg-white dark:bg-gray-800">
      <div className="sm:text-center  py-8 px-4 ">
        <h1 className="mb-4  text-4xl font-extrabold leading-none dark:text-white md:text-5xl xl:text-4xl">Une offre, des dizaines de jeux & script</h1>
        </div>
        <p className="mt-4 max-w-2xl text-xl text-gray-600 dark:text-gray-400 lg:mx-auto sm:text-center">
        C'est ce que nous vous proposons avec notre offre de serveurs de jeux, changez de jeu à votre guise, en seulement 2 clics et sans limite !
         </p>
        <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8 pb-16 sm:pb-24 sm:py-16">
<CarousselGame />
        </div>
      </section>
      <section className="bg-gray-100 dark:bg-gray-900">
      <div className=" py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
          <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
              Nos offres
            </h2>
            <p className="mb-5 font-light text-gray-500 sm:text-xl dark:text-gray-400">
            Découvrez nos différents types de serveurs adaptés à tous vos besoins.
            </p>
          </div>
          
          <CardHome />
          
        </div>
      </section>
      <section className="bg-white dark:bg-gray-800">
      <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8 pb-16 sm:pb-24 sm:py-16">
      <MapChart />
        </div>
      </section>
      <section className="bg-gray-100 dark:bg-gray-900">
      <FAQ />
      <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8 pb-16 sm:pb-24 sm:py-16">
        <div className="relative rounded-2xl px-6 py-10 bg-blue-600 overflow-hidden shadow-xl sm:px-12 sm:py-20">
          <div className="sm:text-center">
            <h2 className="text-3xl font-bold text-white tracking-tight sm:text-4xl">toujours pas sur notre discord ?</h2>
            <p className="mt-6 mx-auto max-w-2xl text-lg text-indigo-200">rejoint le tout de suite ! </p>
          </div>
          <div className="mt-12 sm:flex sm:justify-center">
              <Button>Rejoind le discord</Button>
          </div>
        </div>
        </div>
        <CustomFooter />
      </section>
    </main>
  );
}

export default HomePage;
