"use client";

import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from "../ui/navigation-menu"

import { cn } from "../../lib/utils";
import * as React from "react";

import { Button, DarkThemeToggle } from "flowbite-react";
import favicon from "../../img/Favicon.png";
import { Link } from 'react-router-dom';

const cloud = [
  {
    title: "VPS Linux",
    href: "/docs/primitives/alert-dialog",
    description: "héberger vos projet et application en toute tranquilité. ",
  },
  {
    title: "VPS Windows",
    href: "/docs/primitives/hover-card",
    description: "Controlez votre serveur VPS plus simplement grace a l'interface windows.",
  },
  {
    title: "NAS Synology",
    href: "/docs/primitives/progress",
    description: "Stockez des photo, video, musique, ou des films sur votre propre serveur de stockage NAS.",
  },
];
const information = [
  {
    title: "étudiant ",
    href: "/docs/primitives/progress",
    description: "Découvrez nos offres exclusivement pour les étudiants.",
  },
  {
    title: "Partenaire ",
    href: "/docs/primitives/progress",
    description: "Découvrez ou postulez pour devenir Partenaire et profité d'avantage.",
  },
]

export function CustomNavbar() {
  return (
    <div id="navbar" className="flex items-center justify-between p-4 bg-WHITE dark:bg-gray-800  text-white">
      <div className="flex items-center">
        <a href="https://justmyhost.fr" className="flex items-center">
          <img src={favicon} className="mr-3 h-6 sm:h-9" alt="JustMyHost Logo" />
          <span className="self-center whitespace-nowrap text-xl font-semibold text-black dark:text-white">
            JustMyHost
          </span>
        </a>
      </div>
      <div className="flex items-center space-x-6">
        <NavigationMenu>
        <NavigationMenuList className="flex space-x-6">
          <NavigationMenuItem>
          <Link to="/web">   
            <NavigationMenuLink className={navigationMenuTriggerStyle()}>
              Web
            </NavigationMenuLink>
          </Link>
        </NavigationMenuItem>
        <NavigationMenuItem>
        <Link to="/game" > 
            <NavigationMenuLink className={navigationMenuTriggerStyle()}>
              Serveur de Jeux
            </NavigationMenuLink>
          </Link>
        </NavigationMenuItem>
            <NavigationMenuItem>
              <NavigationMenuTrigger className={navigationMenuTriggerStyle()}>Cloud</NavigationMenuTrigger>
              <NavigationMenuContent>
            <ul className="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-1 lg:w-[400px]">
              {cloud.map((component) => (
                <ListItem
                  key={component.title}
                  title={component.title}
                  href={component.href}
                >
                  {component.description}
                </ListItem>
              ))}
            </ul>
          </NavigationMenuContent>
          </NavigationMenuItem>
          <NavigationMenuItem>
              <NavigationMenuTrigger className={navigationMenuTriggerStyle()}>Information</NavigationMenuTrigger>
              <NavigationMenuContent>
            <ul className="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-1 lg:w-[400px]">
              {information.map((component) => (
                <ListItem
                  key={component.title}
                  title={component.title}
                  href={component.href}
                >
                  {component.description}
                </ListItem>
              ))}
            </ul>
          </NavigationMenuContent>
            </NavigationMenuItem>

          </NavigationMenuList>
        </NavigationMenu>
      </div>
      <div className="flex items-center space-x-2">
        <Button>Espace client</Button>
        <DarkThemeToggle />
      </div>
    </div>
  );
}



const ListItem = React.forwardRef<React.ElementRef<"a">, React.ComponentPropsWithoutRef<"a">>(
  ({ className, title, children, ...props }, ref) => {
    return (
      <li>
        <NavigationMenuLink asChild>
          <a
            ref={ref}
            className={cn(
              "block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
              className
            )}
            {...props}
          >
            <div className="text-sm font-medium leading-none">{title}</div>
            <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
              {children}
            </p>
          </a>
        </NavigationMenuLink>
      </li>
    );
  }
);
ListItem.displayName = "ListItem";
