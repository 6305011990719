import React from "react";
import Slider from "react-slick";

//import des images
import minecraft from '../../img/minecraft.png'
import fivem from '../../img/fivem.png'
import gmod from '../../img/gmod.png'
import ark from '../../img/ark-survival-evolved.png'
import palworld from '../../img/palworld.jpg'
import redm from '../../img/redm.png'


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function CarousselGame() {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 5000,
    cssEase: "linear"
  };
  return (
    <div className="slider-container">
      <Slider {...settings}>
        <div>
        <a className="flex justify-center items-center" href="https://justmyhost.fr/">
        <img
          src={minecraft}
          className="mr-3 h-6 sm:h-9"
          alt="Minecraft logo"
        />
        <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
          Minecraft
        </span>
        </a>
        </div>
        <div>
        <a className="flex justify-center items-center" href="https://justmyhost.fr/">
        <img
          src={fivem}
          className="mr-3 h-6 sm:h-9"
          alt="Fivem logo"
        />
        <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
          Fivem
        </span>
        </a>
        </div>
        <div>
        <a className="flex justify-center items-center" href="https://justmyhost.fr/">
        <img
          src={gmod}
          className="mr-3 h-6 sm:h-9"
          alt="Gmod logo"
        />
        <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
          Garry's Mod
        </span>
        </a>
        </div>
        <div>
        <a className="flex justify-center items-center" href="https://justmyhost.fr/">
        <img
          src={ark}
          className="mr-3 h-6 sm:h-9"
          alt="Ark logo"
        />
        <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
          Ark
        </span>
        </a>
        </div>
        <div>
        <a className="flex justify-center items-center" href="https://justmyhost.fr/">
        <img
          src={palworld}
          className="mr-3 h-6 sm:h-9"
          alt="Palworld logo"
        />
        <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
          Palworld
        </span>
        </a>
        </div>
        <div>
        <a className="flex justify-center items-center" href="https://justmyhost.fr/">
        <img
          src={redm}
          className="mr-3 h-6 sm:h-9"
          alt="Redm logo"
        />
        <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
          RedM
        </span>
        </a>
        </div>
      </Slider>
    </div>
  );
}
