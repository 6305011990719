import { Footer } from "flowbite-react";
import {
  BsDiscord,
  BsFacebook,
  BsGithub,
  BsInstagram,
  BsTwitter,
} from "react-icons/bs";
import favicon from '../../img/Favicon.png'
import payment from '../../img/payment.png'

export function CustomFooter() {
  return (
    <Footer container>
      <div className="w-full">
        <div className="grid w-full justify-between sm:flex sm:justify-between md:flex md:grid-cols-1">
          <div>
            <Footer.Brand
              href="https://justmyhost.fr"
              src={favicon}
              alt="JustMyHost Logo"
              name="JustMyHost"
            />
            <span>‎ </span>
        <a href="https://www.top-heberg.com/hebergeur/justmyhost-527">
					<img src="https://www.top-heberg.com/badge/justmyhost-527?s=refer" width="250" className="shadow-sm align-middle" />
				</a>
        <span>‎ </span>
        <img src={payment} width="175" className="shadow-sm align-middle" />
          </div>
          <div className="grid grid-cols-2 gap-8 sm:mt-4 sm:grid-cols-3 sm:gap-6">
            <div>
              <Footer.Title title="Offres" />
              <Footer.LinkGroup col>
                <Footer.Link href="#">VPS</Footer.Link>
                <Footer.Link href="#">Game</Footer.Link>
                <Footer.Link href="#">Web</Footer.Link>
                <Footer.Link href="#">Script</Footer.Link>
              </Footer.LinkGroup>
            </div>
            <div>
              <Footer.Title title="Liens Utiles" />
              <Footer.LinkGroup col>
              <Footer.Link href="#">Affiliation</Footer.Link>
              <Footer.Link href="https://status.justmyhost.fr">Panel Web</Footer.Link>
              <Footer.Link href="https://163.5.143.238:8443/login_up.php">Panel Web</Footer.Link>
              <Footer.Link href="https://panel.justmyhost.fr">Panel Jeux</Footer.Link>
              <Footer.Link href="https://92.161.217.144:8006">Panel VPS</Footer.Link>
              <Footer.Link href="https://discord.gg/2Mxt9cvXam">Discord</Footer.Link>
              </Footer.LinkGroup>
            </div>
            <div>
              <Footer.Title title="Legal" />
              <Footer.LinkGroup col>
                <Footer.Link href="#">CGU</Footer.Link>
                <Footer.Link href="#">CGV</Footer.Link>
                <Footer.Link href="#">Mentions légales</Footer.Link>

              </Footer.LinkGroup>
            </div>
          </div>
        </div>
        <Footer.Divider />
        <div className="w-full sm:flex sm:items-center sm:justify-between">
          <Footer.Copyright href="#" by="JustMyHost" year={2024} />
          <p className="font-light text-gray-500 dark:text-gray-400 ">
              Made by <a href="https://github.com/MaximeTre">Maxime</a> with React technologies.
            </p>
          <div className="mt-4 flex space-x-6 sm:mt-0 sm:justify-center">
            <Footer.Icon href="https://www.tiktok.com/@justmyhost" icon={BsFacebook} />
            <Footer.Icon href="https://instagram.com/justmyhost.fr" icon={BsInstagram} />
            <Footer.Icon href="https://twitter.com/justmyhost" icon={BsTwitter} />
            <Footer.Icon href="https://github.com/JustMyHost-France/" icon={BsGithub} />
            <Footer.Icon href="https://discord.gg/ycMjfQv5Ub" icon={BsDiscord} />
          </div>
        </div>
      </div>
    </Footer>
  );
}
export default CustomFooter;
