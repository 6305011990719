import { Accordion } from "flowbite-react";

export function FAQ() {
    return(
        <div className="mx-auto grid max-w-screen-xl px-4 py-8 lg:gap-8 lg:py-16 xl:gap-0">
        <Accordion>
        <Accordion.Panel>
          <Accordion.Title>Qu'est-ce qui distingue JustMyHost des autres hébergeurs ?</Accordion.Title>
          <Accordion.Content>
            <p className="mb-2 text-gray-500 dark:text-gray-400">
            Chez JustMyHost, nous mettons un point d'honneur à offrir des services d'hébergement fiables et personnalisés. 
            Nous nous démarquons par notre support client réactif 24/7, notre infrastructure de pointe, et nos offres flexibles qui s'adaptent à tous les besoins. 
            Que vous soyez un particulier ou une entreprise, nous avons une solution d'hébergement qui répond à vos attentes.
            </p>
          </Accordion.Content>
        </Accordion.Panel>
        <Accordion.Panel>
          <Accordion.Title>Comment fonctionne votre système innovant d'hébergement de jeux ?</Accordion.Title>
          <Accordion.Content>
            <p className="mb-2 text-gray-500 dark:text-gray-400">
            Notre offre innovante permet d'héberger non pas un seul jeu, mais une bibliothèque complète de jeux. Avec une seule offre, vous pouvez gérer plusieurs jeux simultanément, offrant ainsi une grande flexibilité et des économies substantielles. 
            ce système est idéal pour les développeurs, les streamers, et les communautés de joueurs qui souhaitent diversifier leur contenu sans les contraintes d'un hébergement limité à un seul titre.
            </p>
          </Accordion.Content>
        </Accordion.Panel>
        <Accordion.Panel>
          <Accordion.Title>Quelles sont les mesures de sécurité mises en place par JustMyHost ?</Accordion.Title>
          <Accordion.Content>
            <p className="mb-2 text-gray-500 dark:text-gray-400">
            La sécurité de vos données est notre priorité. 
            Nous utilisons des pare-feux avancés, un cryptage SSL pour toutes les communications, des sauvegardes automatiques quotidiennes et une surveillance 24/7 pour prévenir toute intrusion. 
            De plus, notre équipe technique est toujours prête à intervenir en cas de besoin pour assurer la continuité de vos services.
            </p>

          </Accordion.Content>
        </Accordion.Panel>
        <Accordion.Panel>
          <Accordion.Title>Quels sont les moyens de paiement acceptés par JustMyHost ?</Accordion.Title>
          <Accordion.Content>
            <p className="mb-2 text-gray-500 dark:text-gray-400">
            Chez JustMyHost, nous offrons une variété de moyens de paiement pour faciliter vos transactions. 
            Vous pouvez régler vos services via PayPal, Paysafecard et carte bancaire. 
            Nous nous efforçons de rendre le processus de paiement aussi simple et sécurisé que possible, en acceptant les principales cartes de crédit et en garantissant la protection de vos informations personnelles grâce à des systèmes de sécurité de pointe.
            </p>
          </Accordion.Content>
        </Accordion.Panel>
      </Accordion>
      </div>
    );
}

