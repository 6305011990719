
function GamePage() {
    return (
      <main className=" bg-primary min-h-screen items-center justify-center gap-2">
        
      </main>
    );
  }
  
  export default GamePage;
  