import React from "react";
import "../../index.css";
import worldmap from "../../img/map-dark.svg"
import { Tooltip } from "flowbite-react";
const MapChart: React.FC = () => {
  return (
    <div className="container">
    <div className="row justify-content-center">
        <div className="col-md-9 col-lg-8">
            <div className="section-heading text-center mb-5">
                <h2>Notre Datacenters</h2>
                <p className="lead">Découvrez notre emplacement sur la carte ci-dessous :</p>
            </div>
        </div>
    </div>
    <div className="row">
        <div className="col-md-12">
            <div className="network-map-wrap">
                <ul className="custom-map-location">
                    <li style={{top: '35%', left: '47%'}}>
                    <Tooltip content="France 🇫🇷 - Montpellier">
                        <span  data-placement="top" title="France"></span>
                        </Tooltip>
                    </li>
                  
                </ul>
                <img src={worldmap} alt="location map" />
            </div>
        </div>
    </div>
</div>
  );
};

export default MapChart;
