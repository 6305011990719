import { DarkThemeToggle, Button, Badge, Card } from "flowbite-react";
import { Link, Routes, Route } from 'react-router-dom';
import { CustomNavbar } from "./components/NavBar/NavBar";
import { CustomFooter } from "./components/Footer/Footer";
import { HiOutlineArrowRight, HiChevronRight } from "react-icons/hi";
import illustration1 from "./img/server-2.webp"
import serverDedie from './img/serveur_dedie.png'
import CarousselGame from './components/Caroussel/CarousselGame'
import { FAQ } from "./components/FAQ/faq";
import GamePage from './GamePage';
import HomePage from './HomePage'

function App() {
  return (
    <Routes>
    <Route path="/" element={<HomePage />} />
    <Route path="/game" element={<GamePage />} />
  </Routes>
  );
}

export default App;
